import WithLoading, {LoadingProps} from "../../components/Loading/WithLoading";
import {useAuth} from "../../components/AuthProvider";
import {useState, ChangeEventHandler, useEffect} from "react";
import {useToast} from "../../components/Toast/ToastProvider";
import styles from "./CreateInRequest.module.css";
import Cookies from "universal-cookie";
import CreateInRequestQr from "./CreateInRequestQr";
import StatusViewer from "./StatusView/StatusViewer";
import qrBannerImage from "../../images/qrBanner.png";
import requestBannerImage from "../../images/approve_app.gif";

const cookie = new Cookies(null, {path: "/"});

const packages = [5, 10, 20, 50, 75, 100];

function CreateInRequest({isLoading, setLoading}: LoadingProps) {
    const {name, suid, numbers, diamonds, t} = useAuth();
    const {showToast} = useToast();

    type modeType = "QR" | "REQUEST";
    const [mode, setMode] = useState<modeType>("QR");

    type statusType = "SelectMode" | "SelectedMode";
    const [status, setStatus] = useState<statusType>("SelectMode");

    type ResultStateType = {heading: string, headingColor: string, info: string, orderText?: string} | null;
    const [isResult, setIsResult] = useState<ResultStateType>(null);
    const [accName, setAccName] = useState(name);
    const [diamondsValue, setDiamondsValue] = useState(Math.floor(diamonds / 2));
    const [amount, setAmount] = useState("0");
    const [pkg, setPkg] = useState(0);

    const onChangeAccName: ChangeEventHandler<HTMLInputElement> = ({target}) => setAccName(target.value);
    const onChangeDiamonds: ChangeEventHandler<HTMLInputElement> = ({target}) => {
        const cDiamonds = parseInt(target.value === "" ? "0" : target.value);
        setDiamondsValue(cDiamonds);
        updateAmount(cDiamonds);
    }

    const updateAmount = (diamonds: number) => setAmount(Math.floor(diamonds / 10).toString());

    useEffect(() => {
        updateAmount(diamondsValue);
    }, []);

    const onClickContinue = () => {
        if (status !== "SelectMode") return;
        if (mode === "REQUEST") {
            setMode("QR");
            showToast("Request method pr kaam chal rha hai. jald mukamal ho jaye ga");
            return;
        }
        setStatus("SelectedMode");
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.container}>
                <div className={styles.nameHolder}>
                    <h3>{name}</h3>
                    <h5>SUID: {suid}</h5>
                </div>

                {
                    isResult == null && <h2>ڈائمنڈ خریدیں</h2>
                }

                {
                    status === "SelectMode" && <StatusViewer maxStatus={5} completed={0} showNumber={() => {}} />
                }

                {
                    !isResult &&
                    <div className={styles.form}>

                        {
                            status === "SelectMode" &&
                            <>
                                <div className={styles.note}>دونوں میں سے کسی ایک کو سلیکٹ کریں</div>
                                <div className={styles.chooseMode}>
                                    <button onClick={() => setMode("QR")}>
                                        <div>
                                            <input type="radio" checked={mode === "QR"} onChange={() => setMode("QR")} />
                                            <h4>QR Code Method</h4>
                                        </div>
                                        <img src={qrBannerImage}  alt={""} />
                                    </button>
                                    <button onClick={() => setMode("REQUEST")}>
                                        <div>
                                            <input type="radio" checked={mode === "REQUEST"} onChange={() => setMode("REQUEST")} />
                                            <h4>Request Method</h4>
                                        </div>
                                        <img src={ requestBannerImage}  alt={""} />
                                    </button>
                                </div>
                                <div className={styles.buttonContainer}>
                                    <button type="submit" id="submitBtn" onClick={onClickContinue}>Continue ►</button>
                                </div>
                            </>
                        }

                        {
                            status === "SelectedMode" && mode === "QR" &&
                            <CreateInRequestQr number={numbers[0]} isLoading={isLoading} setLoading={setLoading} showToast={showToast} goBack={() => setStatus("SelectMode")} />
                        }

                    </div>
                }
            </div>
        </div>
    )
}

export default WithLoading(CreateInRequest);