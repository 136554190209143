import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {HashRouter, Navigate, Route, Routes} from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import {LoadingProvider} from "./components/Loading/LoadingProvider";
import {ToastProvider} from "./components/Toast/ToastProvider";
import {AuthProvider} from "./components/AuthProvider";
import CreateInRequest from "./CreateRequest/InRequest/CreateInRequest";
import CreateOutRequest from "./CreateRequest/OutRequest/CreateOutRequest";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <LoadingProvider initLoadingState={true}>
          <ToastProvider>
              <AuthProvider>
                  <HashRouter basename={"/"}>
                      <Routes>
                          <Route path="/" element={<Navigate to="dashboard" replace={true} />} />
                          <Route path="dashboard" element={<Dashboard />} />
                          <Route path="dashboard/createInRequest" element={<CreateInRequest />} />
                          <Route path="dashboard/createOutRequest" element={<CreateOutRequest />} />
                      </Routes>
                  </HashRouter>
              </AuthProvider>
          </ToastProvider>
      </LoadingProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
