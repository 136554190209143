import $ from "jquery";
import WithLoading, {LoadingProps} from "../../components/Loading/WithLoading";
import {useAuth} from "../../components/AuthProvider";
import {useState, ChangeEventHandler, useEffect} from "react";
import {useToast} from "../../components/Toast/ToastProvider";
import styles from "./CreateOutRequest.module.css";
import Cookies from "universal-cookie";
import diamondImage from "../../images/diamonds.png";
import payImage from "../../images/rs.png";

const cookie = new Cookies(null, {path: "/"});

function CreateOutRequest({setLoading}: LoadingProps) {
    const {name, suid, numbers, diamonds, t} = useAuth();
    const {showToast} = useToast();

    type ResultStateType = {heading: string, headingColor: string, info: string, orderText?: string} | null;
    const [isResult, setIsResult] = useState<ResultStateType>(null);
    const [accName, setAccName] = useState(name);
    const [diamondsValue, setDiamondsValue] = useState(Math.floor(diamonds / 2));
    const [amount, setAmount] = useState("0");

    const onChangeAccName: ChangeEventHandler<HTMLInputElement> = ({target}) => setAccName(target.value);
    const onChangeDiamonds: ChangeEventHandler<HTMLInputElement> = ({target}) => {
        const cDiamonds = parseInt(target.value === "" ? "0" : target.value);
        setDiamondsValue(cDiamonds);
        updateAmount(cDiamonds);
    }

    const updateAmount = (diamonds: number) => setAmount(Math.floor(diamonds / 10).toString());

    useEffect(() => {
        updateAmount(diamondsValue);
    }, []);

    const openEndPopup = (response: {ResultCode: number, Message: string, data: string, OrderId: string}) => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };

        let result: ResultStateType = {info: "", orderText: "", heading: "", headingColor: ""};

        if(response.ResultCode == 4 || response.ResultCode == 5 || response.ResultCode == 8) {
            //invalid
            result.heading = "Invalid Order";
            result.headingColor = "red";
            result.info = "اپنی گیم کو بند کر کے دوبارہ چلائیں۔";
            cookie.remove("data");
        }

        if(response.ResultCode == 1) {
            //low diamonds
            result.heading = "Low Diamonds";
            result.headingColor = "orange";
            result.info = "آپ کے اکاؤنٹ میں ڈائمنڈ کم ہیں۔ اس لیے اپ کی ریکویسٹ کینسل ہو گئی ہے۔";
            cookie.remove("data");
        }

        if(response.ResultCode == 30) {
            //win more match
            result.heading = "Win More!";
            result.headingColor = "orange";
            result.info = "لڈو سے پیسے نکالنے سے پہلے کم از کم \"" + response.data + "\" ڈائمنڈ والے میچ جیتیں۔";
            cookie.remove("data");
        }

        if(response.ResultCode == -1) {
            //widthdraw disabled temporary
            result.heading = "Server Down!";
            result.headingColor = "orange";
            result.info = response.Message;
            cookie.remove("data");
        }

        if(response.ResultCode == 0) {
            result.heading = "Order Successful";
            result.headingColor = "green";
            result.orderText = "Id: " + response.OrderId;
            result.info = "اپ کی ریکویسٹ درج ہو گئی ہے۔ اگلے دو دن میں اپ کے ایزی پیسہ کے موبائل اکاؤنٹ میں پیسے ڈال دیے جائیں گے۔";
        }

        setLoading(false);
        setIsResult(result);
    }

    const onClickSubmit = () => {
        if(accName.length < 3) {
            setAccName(name);
            showToast("Please Check Account Title!");
            return;
        }

        if(diamondsValue > diamonds || diamondsValue < 150) {
            //invalid
            const cDiamonds = diamonds >= 150 ? 150 : 0;
            setDiamondsValue(cDiamonds);
            updateAmount(cDiamonds);
            showToast(diamonds >= 150 ? "Invalid Diamonds!" : "اپ کے پاس کم از کم 150 ڈائمنڈ ہونے چاہیے۔");
        } else {
            //submit
            setLoading(true);
            $.ajax({
                type: "POST",
                url: "https://savegemtransferludowebv2-uisrjlodmq-el.a.run.app",
                contentType: 'application/json',
                data: JSON.stringify({
                    token: t,
                    type: "ep",
                    accTitle: accName,
                    number: numbers[0],
                    amount: parseInt(Math.floor(diamondsValue / 10).toString()),
                    email: suid + "@tellosoft.com"
                }),
                success: openEndPopup,
                error: function(error) {
                    // Handle any errors that occur during the request
                    openEndPopup({ResultCode: 4, Message: "", data: "", OrderId: ""});
                }
            });
        }
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.container}>
                <div className={styles.nameHolder}>
                    <h3>{name}</h3>
                    {/*<h5>SUID: {suid}</h5>*/}
                </div>

                {
                    isResult == null && <h2>ڈائمنڈ بیچیں</h2>
                }

                {
                    isResult &&
                    <div className={styles.resultCardSell}>
                        <h2 style={{color: isResult.headingColor}}>{isResult.heading}</h2>
                        {
                            isResult.orderText && <h6 className={styles.resultCardSellOrderId}>{isResult.orderText}</h6>
                        }
                        <h6 className={styles.resultCardSellInfo}>{isResult.info}</h6>
                    </div>
                }

                {
                    !isResult &&
                    <div className={styles.form}>
                        <div className={styles.haveDiamonds}>You have {diamonds} Diamonds!</div>

                        <br />

                        <div>
                            <label htmlFor="accTitle">Account Title:</label>
                            <input type="text" name="accTitle" max="50" value={accName} onChange={onChangeAccName} />
                        </div>

                        <label htmlFor="mobileNumber">Mobile Number:</label>
                        <input type="text" id="mobileNumber" name="mobileNumber" readOnly value={numbers[0]} />

                        <div className={styles.note}>1 PKR equals to 10 Diamonds</div>

                        <label htmlFor="diamondsAmount" id="diamondsLabel">Enter Diamonds:</label>
                        <div className={styles.iconInputHolder}>
                            <img id="inputIconDiamonds" src={diamondImage} width="35" height="35" />
                            <input type="text" id="diamondsAmount" name="diamondsAmount" className={styles.iconInput} value={diamondsValue} onChange={onChangeDiamonds} />
                        </div>

                        <label htmlFor="rsAmount">Rupees: (Min 15)</label>
                        <div className={styles.iconInputHolder}>
                            <img src={payImage} width="35" height="35" />
                            <input type="number" id="rsAmount" name="rsAmount" value={amount} readOnly />
                        </div>

                        <div className={styles.buttonContainer}>
                            <button type="submit" id="submitBtn" onClick={onClickSubmit}>Submit</button>
                        </div>

                    </div>
                }
            </div>
        </div>
    )
}

export default WithLoading(CreateOutRequest);