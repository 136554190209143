import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {useToast} from "./Toast/ToastProvider";
import {simpleDecrypt, simpleEncrypt} from "./files/encrypter";
import WithLoading from "./Loading/WithLoading";
import Cookies from "universal-cookie";

const cookie = new Cookies(null, {path: "/"});


export type setLoadingType = (isLoading: boolean) => void;

type AuthState = {
    t: string,
    tTime: string,
    numbers: string[],
    diamonds: number,
    name: string,
    suid: string,
    v: "V1" | "V2"
}

type AuthStateCore = {
    t: string,
    tTime: string,
    numbers: "" | "false",
    diamonds: number,
    name: string,
    suid: string,
    v: "V1" | "V2"
}

const defaultAuthState : AuthState = {
    t: "",
    v: "V1",
    name: "...",
    diamonds: 0,
    numbers: [],
    suid: "XXXX-XXXX-XXXX",
    tTime: ""
};

const AuthContext = createContext(defaultAuthState);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = WithLoading(({children}: {children: React.ReactNode}) => {
    const [isAuthenticated, setAuth] = useState<false | AuthState>(false);
    const {showToast} = useToast();

    let intervalTimer: NodeJS.Timer | null = null;
    let intervalAuthTimer = useRef<NodeJS.Timer>();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        let data = urlParams.get('data');
        let dataValueObj: AuthStateCore | null = {
            t: "",
            tTime: "",
            numbers: "",
            diamonds: 0,
            name: "",
            suid: "",
            v: "V2"
        };
        let isLoadedFromCookie = false;

        if(!data) {
            const cookieData = cookie.get("data")?.split("******")?.[0];
            // const cookie = getCookie("data");
            if(cookieData == null || cookieData.length < 20) {
                //invalid cookie
                onDataError();
                return;
            }
            isLoadedFromCookie = true;
            dataValueObj = JSON.parse(simpleDecrypt(cookieData));
        }

        if(!isLoadedFromCookie) {
            try {
                dataValueObj = JSON.parse(simpleDecrypt(data ?? ""));
            } catch (e) {}
        }

        if (dataValueObj == null || dataValueObj.t == null || dataValueObj.t.length < 20) {
            onDataError();
            return;
        }

        if(dataValueObj.v !== "V2" || (dataValueObj.v === "V2" && (typeof dataValueObj.numbers !== "string" || dataValueObj.numbers.length < 5))) {
            onDataError();
            return;
        }

        if(!isLoadedFromCookie) {
            cookie.remove("data");

            // Calculate the expiration time in milliseconds (10 minutes)
            let expirationTime = new Date().getTime() + 10 * 60 * 1000;
            let expirationDate = (new Date(expirationTime)).toUTCString();
            cookie.set("data", simpleEncrypt(JSON.stringify(dataValueObj)) + "******" + expirationDate, {expires: new Date(expirationTime)})

            const url = new URL(window.location.href);
            url.searchParams.delete('data');
            window.history.replaceState({}, document.title, url.toString() + "#/dashboard");
        }

        if(intervalAuthTimer.current == null) {
            const cookieTime = cookie.get("data")?.split("******")?.[1];
            const remainingMs = new Date(cookieTime ?? "").getTime() - new Date().getTime();
            intervalAuthTimer.current = setTimeout(() => {
                clearTimeout(intervalAuthTimer.current);
                setAuth(false);
            }, remainingMs);
        }

        const numbers: string[] = [];
        if(dataValueObj.numbers !== "false") {
            const data = dataValueObj.numbers.split(";");
            for (let i = 0; i < data.length; i++) {
                const number = data[i];
                if(number.length < 5 || numbers.includes(number)) continue
                numbers.push(number);
            }
        }
        setAuth({...dataValueObj, numbers});
    }, []);

    useEffect(() => {
        if(intervalTimer && isAuthenticated !== false) clearInterval(intervalTimer);
    }, [intervalTimer, isAuthenticated]);

    const onDataError = () => {
        showToast("اپنی گیم کو بند کر کے دوبارہ چلائیں۔");
        if(intervalTimer != null) return;
        intervalTimer = setInterval(() => {
            if(isAuthenticated !== false && intervalTimer) {
                clearInterval(intervalTimer);
                return;
            }
            showToast("اپنی گیم کو بند کر کے دوبارہ چلائیں۔");
        }, 6000);
    }

    const data = isAuthenticated === false ? defaultAuthState : isAuthenticated;

    return (
        <AuthContext.Provider value={data}>
            {
                isAuthenticated === false ? (
                    <>
                        <h2 style={{textAlign: 'center', color: 'white', position: 'absolute', top: '70%', width: '100%'}}>اپنی گیم کو بند کر کے دوبارہ چلائیں۔</h2>
                    </>
                ) : children
            }
        </AuthContext.Provider>
    )
}, false);