const key = "dfnksdfshgjngkdfgks3rwerhhrdfsdf";

export function simpleEncrypt(text: string) {
    let encryptedText = '';
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i) ^ key.charCodeAt(i % key.length);
        encryptedText += String.fromCharCode(charCode);
    }
    return btoa(encryptedText);
}

export function simpleDecrypt(encryptedText: string) {
    try {
        encryptedText = atob(encryptedText);
    } catch (e) {
        // console.log(e);
    }
    let decryptedText = '';
    for (let i = 0; i < encryptedText.length; i++) {
        const charCode = encryptedText.charCodeAt(i) ^ key.charCodeAt(i % key.length);
        decryptedText += String.fromCharCode(charCode);
    }
    return decryptedText;
}