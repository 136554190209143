import styles from "./Dashboard.module.css";
import WithLoading, {LoadingProps} from "../components/Loading/WithLoading";
import {useAuth} from "../components/AuthProvider";
import {useNavigate} from "react-router-dom";
import diamondImage from "../images/diamonds.png";
import payImage from "../images/rs.png";

function Dashboard({setLoading}: LoadingProps) {
    const {suid, name, numbers} = useAuth();
    const navigate = useNavigate();

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        }}>
            <div style={{minWidth: "250px"}}>
                <h3 style={{color: "floralwhite", fontWeight: "bolder", margin: 0, padding: 0}}>{name}</h3>
                <h5 style={{color: "lightcyan", fontWeight: "bolder", margin: 0, padding: "10px 0 15px 0"}}>SUID: {suid}</h5>
            </div>

            <div className={styles.container}>
                <h3 className={styles.mainHeading}>Easypaisa Number</h3>
                <form>
                    <div>
                        {
                            numbers.map((value, index) => (
                                <div key={index}>
                                    <input type={"radio"} className={styles.radio} name="selectedNumber" value={value} id={"number" + value} defaultChecked={index === 0} />
                                    <label className={styles.label} htmlFor={"number" + value}>{value}</label>
                                    <br />
                                </div>
                            ))
                        }
                    </div>
                    <br />
                    <button className={`${styles.submitBtn} ${styles.buyDiamonds}`} type="button" onClick={() => navigate("createInRequest")}>
                        <img src={diamondImage} width="34" height="34" className={styles.btnIcon} alt={""} />
                        <span className={styles.btnText}>Buy Diamonds<br />ڈائمنڈ خریدیں</span>
                    </button>
                    <button className={`${styles.submitBtn} ${styles.sellDiamonds}`} type="button" onClick={() => navigate("createOutRequest")}>
                        <img src={payImage} width="34" height="34" className={styles.btnIcon}  alt={""}/>
                        <span className={styles.btnText}>Sell Diamonds<br />ڈائمنڈ بیچیں</span>
                    </button>
                </form>
            </div>
        </div>
    )
}

export default WithLoading(Dashboard);