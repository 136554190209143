import styles from "./StatusViewer.module.css";

function StatusViewer({maxStatus, completed, showNumber}: {maxStatus: number, completed: number, showNumber: (num: number) => void}) {

    const generateItem = () => {
        let item = [];
        for (let i = 0; i < maxStatus; i++) {
            item.push(
                <button key={"h4" + i} className={i < completed ? styles.done : (i === completed ? styles.active : "")} onClick={() => {
                    if (completed >= (i + 1)) showNumber(i + 1);
                }}>
                    {i + 1}
                </button>
            )
            if(maxStatus !== (i + 1)) item.push(<span key={"sp" + i}>►</span>)
        }
        return item;
    }

    return (
        <div className={styles.statusContainer}>
            {
                generateItem()
            }
        </div>
    )
}

export default StatusViewer;