import {ComponentType, useEffect} from 'react';
import {setLoadingType, useLoading} from "./LoadingProvider";

export type LoadingProps = { setLoading: setLoadingType, isLoading: boolean }

const WithLoading = <P extends object> (WrappedComponent: ComponentType<P & LoadingProps>, autoHide: boolean = true) => {
    return (props: P) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const {isLoading, setLoading} = useLoading();

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            if(autoHide) setLoading(false);
            return () => setLoading(true);
        }, []);

        return (
            <WrappedComponent {...props} setLoading={setLoading} isLoading={isLoading} />
        )
    };
}

export default WithLoading;