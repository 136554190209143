import "./loading.css";
import React, {createContext, useContext, useState} from "react";

export type setLoadingType = (isLoading: boolean) => void;

type LoadingState = {
    isLoading: boolean;
    setLoading: setLoadingType;
}

const defaultLoadingState : LoadingState = {
    isLoading: true,
    setLoading: () => {}
};

const LoadingContext = createContext(defaultLoadingState);

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({children, initLoadingState}: {children: React.ReactNode, initLoadingState: boolean}) => {
    const [isLoading, setLoading] = useState<boolean>(initLoadingState);

    return (
        <LoadingContext.Provider value={{isLoading, setLoading}}>
            {
                isLoading && (
                    <div id="loading" className="loading">
                        <div id="loadingBg" className="loadingBg" style={{
                            backgroundImage: "url('images/loading.gif')",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center"
                        }}></div>
                        <h4 className="loadingText">Loading...</h4>
                    </div>
                )
            }
            {children}
        </LoadingContext.Provider>
    )
};