import $ from 'jquery';
import "./toast.css";
import React, {createContext, useContext, useEffect, useState} from "react";

export type showToastType = (msg: string, time?: number) => void;

type ToastState = {
    isShowing: boolean;
    showToast: showToastType;
}

const defaultToastState : ToastState = {
    isShowing: false,
    showToast: (msg, time) => {}
};

const ToastContext = createContext(defaultToastState);

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({children}: {children: React.ReactNode}) => {
    const [message, setMessage] = useState("");
    const [isShowing, setIsShowing] = useState(false);

    const $toast = $("#toast");

    useEffect(() => {
        if(isShowing) {
            $toast.fadeIn();
        } else {
            $toast.fadeOut();
        }
    }, [$toast, isShowing]);

    const showToast = (msg: string, time?: number) => {
        if(isShowing) return;
        setMessage(msg);
        setIsShowing(true);
        setTimeout(function() {
            setIsShowing(false);
        }, (time ?? 2) * 1000);
    }

    return (
        <ToastContext.Provider value={{showToast: showToast, isShowing: message !== ""}}>
            {
                <div className="toast" id="toast">{message}</div>
            }
            {children}
        </ToastContext.Provider>
    )
};