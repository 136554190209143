import styles from "./CreateInRequest.module.css";
import diamondImage from "../../images/diamonds.png";
import payImage from "../../images/rs.png";

function BuyItem({price, isSelected, onSelected}: {price: number, isSelected: boolean, onSelected: () => void;}) {
    return (
        <button onClick={onSelected}>
            <div className={styles.pkgItem}>
                <div className={styles.priceContainer}>
                    <input type={"radio"} value={1} checked={isSelected} onChange={onSelected} />
                    <img src={payImage} alt="" />
                    <h4>{price}</h4>
                </div>
                <div className={styles.diamondContainer}>
                    <img src={diamondImage} width="35" height="35" alt="" />
                    <h4>{price * 10}</h4>
                </div>
            </div>
        </button>
    )
}

export default BuyItem;