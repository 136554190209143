import {LoadingProps} from "../../components/Loading/WithLoading";
import {useState} from "react";
import {showToastType} from "../../components/Toast/ToastProvider";
import styles from "./CreateInRequest.module.css";
import BuyItem from "./BuyItem";
import StatusViewer from "./StatusView/StatusViewer";
import diamondImage from "../../images/diamonds.png";
import payImage from "../../images/rs.png";

const packages = [5, 10, 25, 50, 100, 250, 500];

function CreateInRequestQr({setLoading, showToast, number, goBack}: LoadingProps & {showToast: showToastType, number: string, goBack: () => void}) {

    type statusType = "Pkg" | "DownloadQR" | "Learn1" | "Learn2" | "Learn3";
    const [status, setStatus] = useState<statusType>("Pkg");
    const [pkg, setPkg] = useState(0);

    // type ResultStateType = {heading: string, headingColor: string, info: string, orderText?: string} | null;
    // const [isResult, setIsResult] = useState<ResultStateType>(null);

    // const openEndPopup = (response: {ResultCode: number, Message: string, data: string, OrderId: string}) => {
    //     window.history.pushState(null, "", window.location.href);
    //     window.onpopstate = function () {
    //         window.history.go(1);
    //     };
    //
    //     let result: ResultStateType = {info: "", orderText: "", heading: "", headingColor: ""};
    //
    //     if(response.ResultCode == 4 || response.ResultCode == 5 || response.ResultCode == 8) {
    //         //invalid
    //         result.heading = "Invalid Order";
    //         result.headingColor = "red";
    //         result.info = "اپنی گیم کو بند کر کے دوبارہ چلائیں۔";
    //         cookie.remove("data");
    //     }
    //
    //     if(response.ResultCode == 1) {
    //         //low diamonds
    //         result.heading = "Low Diamonds";
    //         result.headingColor = "orange";
    //         result.info = "آپ کے اکاؤنٹ میں ڈائمنڈ کم ہیں۔ اس لیے اپ کی ریکویسٹ کینسل ہو گئی ہے۔";
    //         cookie.remove("data");
    //     }
    //
    //     if(response.ResultCode == 30) {
    //         //win more match
    //         result.heading = "Win More!";
    //         result.headingColor = "orange";
    //         result.info = "لڈو سے پیسے نکالنے سے پہلے کم از کم \"" + response.data + "\" ڈائمنڈ والے میچ جیتیں۔";
    //         cookie.remove("data");
    //     }
    //
    //     if(response.ResultCode == -1) {
    //         //widthdraw disabled temporary
    //         result.heading = "Server Down!";
    //         result.headingColor = "orange";
    //         result.info = response.Message;
    //         cookie.remove("data");
    //     }
    //
    //     if(response.ResultCode == 0) {
    //         result.heading = "Order Successful";
    //         result.headingColor = "green";
    //         result.orderText = "Id: " + response.OrderId;
    //         result.info = "اپ کی ریکویسٹ درج ہو گئی ہے۔ اگلے دو دن میں اپ کے ایزی پیسہ کے موبائل اکاؤنٹ میں پیسے ڈال دیے جائیں گے۔";
    //     }
    //
    //     setLoading(false);
    //     setIsResult(result);
    // }

    const onClickContinue = () => {

        if(status === "Pkg") {
            setStatus("DownloadQR");
            return;
        }

        if(status === "DownloadQR") {
            setStatus("Learn1");
            return;
        }

        if(status === "Learn1") {
            setStatus("Learn2");
            return;
        }

        if(status === "Learn2") {
            setStatus("Learn3");
            return;
        }

        if(status === "Learn3") {
            showToast("Opening Easypaisa App...")
            setTimeout(() => {
                window.location.href = "https://easypaisa.onelink.me/qr";
            }, 1500);
        }
    }

    const convertPackageToNumber = () => {
        switch (status) {
            case "Pkg":
                return 1;
            case "DownloadQR":
                return 2;
            case "Learn1":
                return 3;
            case "Learn2":
                return 4;
            case "Learn3":
                return 5;
        }
    }

    return (
        <>
            <StatusViewer maxStatus={6} completed={convertPackageToNumber()} showNumber={(num) => {
                if(num === 1) {
                    goBack();
                    return;
                }
                setStatus(num === 2 ? "Pkg" : (num === 3 ? "DownloadQR" : (num === 4 ? "Learn1" : (num === 5 ? "Learn2" : "Learn3"))));
            }} />
            {
                status === "Pkg" &&
                <>
                    <label htmlFor="mobileNumber">Easypaisa Number:</label>
                    <input type="text" id="mobileNumber" name="mobileNumber" readOnly value={number} />

                    <div className={styles.note}>1 PKR equals to 10 Diamonds</div>
                    <div className={styles.note} style={{color: "#0056b3"}}>کسی ایک کو سلیکٹ کریں</div>

                    <div className={styles.pkgContainer}>
                        {
                            packages.map((value, index) => (
                                <BuyItem key={index} price={value} isSelected={index === pkg} onSelected={() => setPkg(index)} />
                            ))
                        }
                    </div>

                    <div className={styles.buttonContainer}>
                        <button type="submit" id="submitBtn" onClick={onClickContinue}>Continue ►</button>
                    </div>
                </>
            }

            {
                status === "DownloadQR" &&
                <>
                    <label htmlFor="mobileNumber">Easypaisa Number:</label>
                    <input type="text" id="mobileNumber" name="mobileNumber" readOnly value={number} />

                    <div className={styles.qrHolder}>
                        <div>
                            <img src={payImage} alt="" />
                            <h3>Rs {packages[pkg]}</h3>
                            <span>&#8594;</span>
                            <img src={diamondImage} alt="" />
                            <h3>{packages[pkg] * 10} Diamonds</h3>
                        </div>
                        <img src={"../../images/qr/ludo_rs"+packages[pkg]+".png"} alt="qr" />
                    </div>

                    <div className={styles.buttonContainer}>
                        <a href={"../../images/qr/ludo_rs"+packages[pkg]+".png"} download>
                            <button type="submit" id="submitBtn" onClick={onClickContinue}>Download ►</button>
                        </a>
                    </div>
                </>
            }

            {
                status === "Learn1" &&
                <>
                    <div className={styles.learn}>
                        <h4>ایزی پیسہ ایپ کو کھولیں اور سکین پر کلک کریں</h4>
                        <img src="../../images/qr/learn/learnstep1.png" alt="" height={"350"} />
                    </div>
                    <div className={styles.buttonContainer}>
                        <button type="submit" id="submitBtn" onClick={onClickContinue}>Continue ►</button>
                    </div>
                </>
            }

            {
                status === "Learn2" &&
                <>
                    <div className={styles.learn}>
                        <h4>یہاں سے گیلری پر کلک کریں</h4>
                        <img src="../../images/qr/learn/learnstep2.png" alt="" height={"350"} />
                    </div>
                    <div className={styles.buttonContainer}>
                        <button type="submit" id="submitBtn" onClick={onClickContinue}>Continue ►</button>
                    </div>
                </>
            }

            {
                status === "Learn3" &&
                <>
                    <div className={styles.learn}>
                        <h4>گیلری میں سے کیو ار کوڈ کی تصویر سلیکٹ کریں</h4>
                        <img src="../../images/qr/learn/learnstep3.png" alt="" height={"350"} />
                    </div>
                    <div className={styles.buttonContainer}>
                        <button type="submit" id="submitBtn" onClick={onClickContinue}>Open Easypaisa ►</button>
                    </div>
                </>
            }
        </>
    )
}

export default CreateInRequestQr;